@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.account-main {
    padding: 74px 0;
}

.banner-heading {
    max-width: 753px;
    margin: 0 auto 45px;
    text-align: center;
}

.account-container {
    height: 100%;
    border-radius: 30px;
    border: 1px solid #2C389E;
    background: #000212;
    box-shadow: 2px 0px 14px 0px rgba(255, 255, 255, 0.5); /* x-offset, y-offset, blur-radius, spread-radius, color */
}

.account-container .account_heading {
    padding: 22px 40px;
    border-bottom: 1px solid #2C389E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.account-container .account_heading .property-heading-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.account-container > div:not(:last-child) {
    border-bottom: 1px solid #2C389E;
    padding: 22px 40px;
}

.account-container .account_heading h3 {
    color: #146EF5;
    text-align: left;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.account-container .account_heading .property-heading-right p {
    color: #146EF5;
    font-family: 'Samsung Sharp Sans Medium';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.account-container .account_heading .property-heading-right > *:not(:last-child) {
    margin-right: 16px;
}

.addproperty_links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.unlock-all-button {
    color: #146EF5;
    font-family: 'Samsung Sharp Sans Medium';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline !important;
    background: transparent;
    padding: 0;
    border: none;
    margin-top: 20px;
}

.property_list ul li {
    padding: 20px 0;
}

.tiles-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.tile {
  background: #151530;
  border-radius: 16px;
  border: 1px solid #2C389E;
  padding: 15px 10px;
  width: 350px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: -1px 4px 10px 0px rgba(255, 255, 255, .5); /* offset-x | offset-y | blur-radius | spread-radius | color */
}

.tile h3 {
  color: #146EF5;
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 18px;
}

.tile p {
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Samsung Sharp Sans Medium';
  font-size: 14px;
  margin-top: 10px;
}

.tile:hover {
  transform: translateY(-5px);
  box-shadow: 0px 2px 20px 5px rgba(255, 255, 255, .5); /* offset-x | offset-y | blur-radius | spread-radius | color */
}

.ConnectedStatement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.IntegrationsOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.IntegrationsOptions button {
  font-size: 0.9em;
  margin: 0 10px;
  color: #146EF5;
  background: none;
  border: none;
}

.IntegrationsOptions p {
  font-size: 0.9em;
  color: white;
  margin: 0 10px;
}

.tabs-container {
  display: inline-flex;
  gap: 40px;
}

.tab-button {
  color: #146EF5;
  background: #151530;
  border: 1px solid #146EF5;
  border-radius: 30px;
  padding: 6px 12px;
  cursor: pointer;
  box-shadow: 0px 2px 15px rgba(255, 255, 255, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.tab-button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 2px 15px rgba(255, 255, 255, 0.4);
}

.tab-button.active {
  background-color: #146EF5;
  color: #FFF
}

@media only screen and (max-width: 1199px) {
    .account-container {
        border-radius: 10px;
    }

    .account-container > div:not(:last-child) {
        padding: 18px 20px;
    }

    .account-container .account_heading h3 {
        font-size: 18px;
    }

    .tile {
        width: 300px;
    }

    
}

@media only screen and (max-width: 991px) {
    .banner-heading {
        margin-bottom: 25px;
    }

    .tile {
        width: 250px;
    }
}

@media only screen and (max-width: 767px) {
    .account-container > div:not(:last-child) {
        padding: 15px;
    }

    .account-container .account_heading h3 {
        font-size: 17px;
    }

    .account-container .account_heading .property-heading-right {
        width: 100%;
        margin-top: 20px;
    }

    .property_list ul li {
        padding: 15px 0;
    }

    .tile {
        width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .account-container .account_heading .property-heading-right {
        justify-content: space-between;
    }

    .account-container .account_heading .property-heading-right .form-switch.custom_switch {
        margin: 0 !important;
    }

    .account-container .account_heading .property-heading-right .expendable_search {
        margin-left: auto;
    }
}