.banner {
  padding: 68px 0;
  position: relative;
}
.banner::after {
  content: "";
  display: inline-block;
  width: 928px;
  height: 100%;
  background: url(../../../../public/img/hero-bg-line.webp) no-repeat center
    center;
  position: absolute;
  top: -32%;
  right: 0%;
  z-index: -1;
}
.banner::before {
  content: "";
  display: block;
  width: 387px;
  height: 387px;
  background: #146ef5;
  border-radius: 500px;
  filter: blur(249px);
  position: absolute;
  top: 50%;
  left: -175px;
  z-index: 1;
}

.banner .banner-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  text-align: center;
  width: 100%;
  max-width: 942px;
  margin: 0 auto;
}

.banner .banner-container p {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}
.rating-part-banner i {
  color: #f9da6b !important;
  font-size: 18px;
}
.bg-shadow {
  content: "";
  display: block;
  width: 353px;
  height: 353px;
  background: #146ef5;
  border-radius: 500px;
  filter: blur(249px);
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
}

/* .banner .banner-container a {
    color: #146EF5;
    font-style: normal;
    line-height: normal;
    border-radius: 160px;
    border: 1px solid #146EF5;
    display: inline-block;
    padding: 13px 40px;
    background: transparent;
} */

.banner .banner-container .heading h1 {
  position: relative;
}
.banner .banner-container .rating-part-banner {
  position: relative;
}
/* .banner .banner-container .rating-part-banner::before {
  content: "";
  display: inline-block;
  width: 77px;
  height: 48px;
  background: url(../../../../public/img/heading-line.webp) no-repeat center
    center;
  position: absolute;
  top: 30px;
  left: 84px;
} */
.star-content {
  z-index: 1;
  position: relative;
}
.banner .banner-container .heading h1::after {
  content: "";
  display: inline-block;
  width: 37px;
  height: 37px;
  background: url(../../../../public/img/star.png) no-repeat center center;
  margin-left: 7px;
  vertical-align: middle;
}

.banner .banner-container .banner-video {
  width: 100%;
  max-width: 942px;
  position: relative;
  margin: auto;
}

.banner .banner-container .banner-video::before {
  content: "";
  position: absolute;
  top: 15px;
  width: 281.182px;
  height: 281.182px;
  left: 25px;
  border-radius: 281.182px;
  background: #146ef5;
  filter: blur(90px);
  transform: rotate(20.641deg);
  flex-shrink: 0;
}

.banner .banner-container .banner-video::after {
  content: "";
  position: absolute;
  right: 40px;
  bottom: 50px;
  width: 381.247px;
  height: 381.247px;
  transform: rotate(20.641deg);
  flex-shrink: 0;
  border-radius: 381.247px;
  background: #146ef5;
  filter: blur(90px);
}

.banner .banner-container .banner-video video {
  width: 100%;
  border-radius: 20px;
  border: 3px solid #146ef5;
  height: 535px;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 991px) {
  .banner {
    padding: 40px 0;
    overflow: hidden;
  }

  .banner .banner-container .banner-video video {
    height: 397px;
  }
  .banner .banner-container .rating-part-banner::before {
    left: -40px;
  }
}

@media only screen and (max-width: 767px) {
  section.banner .container {
    width: 100%;
    max-width: 100%;
  }
  .banner .banner-container .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .banner .banner-container .link-btn {
    margin-bottom: 20px;
    margin-right: 0 !important; /* Forces override of inline style */
    width: 80%;
    max-width: 300px;
  }

  .banner .banner-container .banner-video::before {
    top: 20px;
    width: 120px;
    height: 120px;
  }

  .banner .banner-container .banner-video::after {
    bottom: 63px;
    width: 200px;
    height: 200px;
    right: 76px;
  }

  .banner .banner-container .banner-video video {
    height: 295px;
  }

  .banner .banner-container p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .banner .banner-container .rating-part-banner::before {
    left: 0px;
  }
}

@media only screen and (max-width: 575px) {
  .banner .banner-container .banner-video::before {
    top: 20px;
    width: 120px;
    height: 120px;
  }

  .banner .banner-container .banner-video::after {
    width: 100px;
    height: 100px;
  }

  .banner .banner-container .banner-video video {
    height: auto;
  }
}
